@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --SD100: #0b0d0f;
  --SD075: #16191d;
  --SD050: #2e3338;
  --SD025: #464c53;
  --SD010: #777f88;
  --SL100: #fff;
  --SL075: #fcfcfc;
  --SL050: #f2f2f3;
  --SL025: #e5e5e6;
  --S100: #c60000;
  --S075: #ed0000;
  --S050: #ff1515;
  --S025: #ff6363;
}

body {
  margin: 0;
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #000;

  color: var(--SL100);
}

.main-page {
  background-image: url(/public/imgs/jbg.png);
  background-repeat: no-repeat;
  background-position: 140% 0;
  background-size: 60% auto;
}
@media (min-width: 640px) {
  .main-page {
    background-size: 40% auto;
    background-position: 98% 0;
  }
}
@media (min-width: 1280px) {
  .main-page {
    background-size: 30% auto;
    background-position: 80% 0;
  }
}

.detail > li > div {
  padding: 112px 32px 32px 32px;
  background-position: top right;
  background-size: 160px;
  background-repeat: no-repeat;
  border-radius: 16px;
}
.detail > li:first-child > div {
  background-image: url(/public/imgs/bg-40.png);
}
.detail > li:first-child + li > div {
  background-image: url(/public/imgs/53.png);
}
.detail > li:first-child + li + li > div {
  background-image: url(/public/imgs/13.png);
}
